import React from "react";
import parse from 'html-react-parser';
import "./Resume.scss";
// see https://react-icons.github.io/react-icons/
import { FaBook, FaGamepad, FaOrcid, FaTrophy, FaGithub, FaYoutube, FaLinkedin, FaGraduationCap, FaLinkedinIn, FaLink, FaQuestionCircle, FaKey } from "react-icons/fa";
import { BsTerminalFill } from "react-icons/bs";
import { ImLibrary, ImBooks } from "react-icons/im";
import { IoIosCreate } from "react-icons/io";
import { MdOutlineMail, MdOutlineSchool, MdInfoOutline } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import { GoHome } from "react-icons/go";
import { RiQuillPenLine } from "react-icons/ri";
import { CgFileDocument } from "react-icons/cg";
import { GiFairyWand } from "react-icons/gi";
import { LiaChalkboardTeacherSolid } from "react-icons/lia";

class Resume extends React.Component {
  
  static LumTag = "Lum AI";

  slugifyText(str) {
    return str.replace(/[()]+/g, "").replace(/[&]/g, "and").replace(/[\s.!?'"]+/g, '-').toLowerCase();
  }

  skillLevel(level) {
    let stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < level) {
        stars.push(<span className="full-slide" key={i} />);
      } else {
        stars.push(<span className="empty-slide" key={i} />);
      }
    }
    return (
      <span className="slider">
        {stars.map(elem => {
          return elem;
        })}
      </span>
    );
  }

  getMiscSection(props) {
    if(props.miscSection) {
      return (<section>{props.miscSection}</section>);
    } else {
      return null;
    }
  }

  renderPublication(entry) {
    return (
      <div
        className="subsection"
      >

      </div>
    );
  }

  renderCourse(entry) {
    return (
      <div
        className="subsection"
      >

      </div>
    );
  }

  renderAccomplishments(job) {
    if ((typeof job.accomplishments !== "undefined")) {
  
      const bullets = job.accomplishments.map((acc, idx) => <li key={idx + job.company + job.title}>{parse(acc)}</li>);
      return (
        <div className="contributions">
        <h5>Key accomplishments</h5>
        <ul>
          {bullets}
        </ul>
        </div>
      );
    } else {
      return null;
    }
  }

  static customTagSort(a, b) {
    // ensure Lum tag is last
    //console.log(`Tag entry a: ${JSON.stringify(a)}`)
    //console.log(`Tag entry b: ${JSON.stringify(b)}`)
    if (a.tag === Resume.LumTag) {
      //console.log(`Found a == ${a.tag}`)
      return -1
    } else if (b.tag.trim() === Resume.LumTag) {
      //console.log(`Found b == ${b.tag}`)
      return 1
    } else {
      //console.log(`a.tag.localeCompare(b.tag) -> ${a.tag.localeCompare(b.tag)}`);
      return a.tag.localeCompare(b.tag)
    }
  }
  
  renderContributions(project) {
    if ((typeof project.contributions !== "undefined")) {
      const bullets = project.contributions.map((contribution, idx) => <li key={idx + project.name}>{parse(contribution)}</li>);
      return (
        <div className="contributions">
        <h5>Key contributions</h5>
        <ul>
          {bullets}
        </ul>
        </div>
      );
    } else {
      return null;
    }
  }

  renderThesis(entry) {
    let thesisDescription;
    if (entry.thesisDescription) {
      thesisDescription = <div><div className="thesis-description"><FaQuestionCircle className="icon" /><span>{entry.thesisDescription}</span></div></div>;
    } else {
      thesisDescription = null;
    }
    if ((typeof entry.thesis !== "undefined") && (typeof entry.thesisLink !== "undefined")) { 
      return (
        <div>
          <div className="info-row"><FaBook className="icon" /><a href={entry.thesisLink}>{entry.thesis}</a></div>
          {thesisDescription}
        </div>
      )
    } else if ((typeof entry.thesis !== "undefined") && (typeof entry.thesisLink === "undefined")) { 
      return (
        <div>
          <div className="info-row"><FaBook className="icon" /><span className="no-url">{entry.thesis}</span></div>
          {thesisDescription}
        </div>
      )
    } else { return null } 
  }

  renderDegree(entry) {
    const degreeId = "degree" + "-" + this.slugifyText(entry.school) + "-" + this.slugifyText(entry.degree) + "-" + this.slugifyText(entry.major);
    return (
      <div
        className="subsection"
        key={entry.school + entry.department + entry.degree}
      >
        <div className="row">
          <div className="column-period">
            <span className="time-period">{entry.period}</span>
          </div>
          <div className="column-description">
            <a id={degreeId} href={"#" + degreeId}>
              <h4>
                {entry.degree} ({entry.major})
              </h4>
            </a>
            <div className="description">
            <div className="info-row"><span className="subheader">Institution</span>{entry.school}</div>
              {/* <div>Major: {entry.major}</div> */}
              { (typeof entry.minor !== "undefined") ? <div className="info-row"><span className="subheader">Minor</span>{entry.minor}</div> : null }
              { this.renderThesis(entry) }
            </div>
          </div>
        </div>   
      </div>
    );
  }

  renderKeyword(entry) {
    const cls = (entry.tag === Resume.LumTag) ? "lum-keyword" : "resume-keyword";
    return (
      <span className="keywordWrapper">
        { (typeof entry.url !== "undefined") ? <a href={entry.url}><span className={cls}>{entry.tag}</span></a> : <span className={cls}>{entry.tag}</span> } 
      </span>
    );
  }

  renderProject(entry) {
    const projId = "project" + "-" + this.slugifyText(entry.name);
    return (
      <div
        className="subsection"
        key={entry.name}
      >
        <div className="row">
          <div className="column-period">
            <span className="time-period">{entry.period}</span>
          </div>
          <div className="column-description">
            <a id={projId} href={"#" + projId}>
              <h4 className="project-name">
                {entry.name}
              </h4>
            </a>
            <div className="description">
              {entry.description}
              {this.renderContributions(entry)}
              <div className="resume-keywords">
                {/* <FaKey className="icon" /> */}
                {/* <span className="subheader">Keywords</span> */}
                {entry.keywords.sort(Resume.customTagSort).map(kw => this.renderKeyword(kw))}</div>
              <div className="project-links">
                { (typeof entry.codeLink !== "undefined") ? <span className="project-link"><a href={entry.codeLink}><FaGithub className="icon" />Code</a></span> : null } 
                { (typeof entry.demoLink !== "undefined") ? <span className="project-link"><a href={entry.demoLink}><FaGamepad className="icon" />Demo</a></span> : null } 
                { (typeof entry.videoLink !== "undefined") ? <span className="project-link"><a href={entry.videoLink}><FaYoutube className="icon" />Video</a></span> : null }
                { (typeof entry.paperLink !== "undefined") ? <span className="project-link"><a href={entry.paperLink}><FaBook  className="icon" />Paper</a></span> : null }
              </div>
            </div>
          </div>
        </div>   
      </div>
    );
  }

  renderAward(entry) {
    const awardId = "award" + "-" + this.slugifyText(entry.organization) + "-" + this.slugifyText(entry.name) + "-" + this.slugifyText(entry.date);
    return (
      <div
        className="subsection"
        key={entry.name}
      >
        <div className="row">
          <div className="column-period">
            <span className="time-period">{entry.date}</span>
          </div>
          <div className="column-description">
            <a id={awardId} href={"#" + awardId}>
              <h4>
                {entry.name}
              </h4>
            </a>
            <div className="description">
              {entry.description}
              <div><span className="subheader">Organization</span>{entry.organization}</div>
              <div className="project-links">
                { (typeof entry.codeLink !== "undefined") ? <span className="project-link"><a href={entry.codeLink}><FaGithub className="icon" />Code</a></span> : null } 
                { (typeof entry.demoLink !== "undefined") ? <span className="project-link"><a href={entry.demoLink}><FaGamepad className="icon" />Demo</a></span> : null } 
                { (typeof entry.videoLink !== "undefined") ? <span className="project-link"><a href={entry.videoLink}><FaYoutube className="icon" />Video</a></span> : null }
                { (typeof entry.paperLink !== "undefined") ? <span className="project-link"><a href={entry.paperLink}><FaBook  className="icon" />Paper</a></span> : null }
              </div>
            </div>
          </div>
        </div>   
      </div>
    );
  }

  renderGrant(entry) {
    const grantId = "grant" + "-" + this.slugifyText(entry.name) + "-" + this.slugifyText(entry.agency);
    return (
      <div
        className="subsection"
        key={entry.name}
      >
        <div className="row">
        <div className="column-period">
          <span className="time-period">{entry.period}</span>
        </div>
          <div className="column-description">
            <a id={grantId} href={"#" + grantId}>
              <h4>
                {entry.name}
              </h4>
            </a>
            <div className="description">
              <table>
                <tbody>
                  <tr><td className="subheader">Agency</td><td>{entry.agency}</td></tr>
                  <tr><td className="subheader">Role</td><td>{entry.role}</td></tr>
                  <tr><td className="subheader">URL</td><td><a href={entry.url}><span className="url">{entry.url}</span></a></td></tr>
                  <tr><td className="subheader">Award</td><td>{entry.award}</td></tr>
                </tbody>
              </table>
              {/* <div><span className="subheader">Agency</span>{entry.agency}</div>
              <div><span className="subheader">Role</span>{entry.role}</div>
              <div><span className="subheader">URL</span><a href={entry.url}><span className="url">{entry.url}</span></a></div>
              <div><span className="subheader">Award</span>{entry.award}</div> */}
              {entry.description}
              {this.renderContributions(entry)}
              <div className="project-links">
                { (typeof entry.codeLink !== "undefined") ? <span className="project-link"><a href={entry.codeLink}><FaGithub className="icon" />Code</a></span> : null } 
                { (typeof entry.demoLink !== "undefined") ? <span className="project-link"><a href={entry.demoLink}><FaGamepad className="icon" />Demo</a></span> : null } 
                { (typeof entry.videoLink !== "undefined") ? <span className="project-link"><a href={entry.videoLink}><FaYoutube className="icon" />Video</a></span> : null }
                { (typeof entry.paperLink !== "undefined") ? <span className="project-link"><a href={entry.paperLink}><FaBook  className="icon" />Paper</a></span> : null }
              </div>
            </div>
          </div>
        </div>   
      </div>
    );
  }

  render() {
    return (
      <div
        id="resume-component"
      >
        <div id="about" className="about pagebreak">
          <div className="about-contents">
            <div id="profile-image">
              <this.props.profileImage />
            </div>
            <div id="contact">
              <h2>
                <RiQuillPenLine className="icon" />
                Contact
              </h2>
              <div>
                <MdOutlineMail className="icon" />
                <span className="url">{this.props.email}</span>
              </div>
              <div>
                <FiPhone className="icon" />
                {this.props.tel}
              </div>
              <div>
                <GoHome className="icon" />
                {this.props.address}
              </div>
            </div>
            <div id="misc">
              <h2>
                <FaLink className="icon" />
                Links
              </h2>
              <div>
                <CgFileDocument className="icon" />
                <a className="social-link" href="https://parsertongue.org/resume/">Résumé</a>
              </div>
              <div>
                <MdOutlineSchool className="icon" />
                <a className="social-link" href="https://public.parsertongue.org/cv.pdf">CV</a>
              </div>
              <div>
                <FaGithub className="icon" />
                <a className="social-link" href={"https://github.com/" + this.props.githubAccount}>@{this.props.githubAccount}</a>
              </div>
              <div>
                <FaLinkedin className="icon" />
                <a className="social-link" href={"https://www.linkedin.com/in/" + this.props.linkedInAccount}>@{this.props.linkedInAccount}</a>
              </div>
              <div>
                <FaOrcid className="icon" />
                <a className="social-link" href={"https://orcid.org/" + this.props.orcidId}>{this.props.orcidId}</a>
              </div>
              <div>
                <FaYoutube className="icon" />
                <a className="social-link" href="https://www.youtube.com/channel/UCOnt-GDo75McovZHHSb7WkQ">YouTube</a>
              </div>
            </div>
          </div>
        </div>
        <div id="main">
          <h1>{this.props.name}</h1>
          <h3 id="profession">{this.props.profession}</h3>
          <section>
            <div className="summary">
              <h2>
                <MdInfoOutline className="icon" />
                About
              </h2>
              <div>{this.props.summary}</div>
            </div>
          </section>
          <section>
          <a id="experience" href="#experience"><h2>
              <BsTerminalFill className="icon" />
              Experience
            </h2></a>
            {this.props.experiences.map(job => {
              const profId = "job-" + this.slugifyText(job.company) + "-" + this.slugifyText(job.title);
              const cls = (job.company === Resume.LumTag) ? "company lum-company" : "company"
              return (
                <div className="subsection" key={job.company + job.title}>
                  <div className="row">
                    <div className="column-period">
                      <span className="time-period">{job.period}</span>
                    </div>
                    <div className="column-description">
                      <h4><a id={profId} href={"#" + profId}><span className="position">{job.title}</span> @ <span className={cls}>{job.company}</span></a></h4>
                        {/* <h4 id="company">{job.company}</h4> */}
                        {/* <div className="job-description">
                          {job.description}
                          {this.renderAccomplishments(job)}
                        </div> */}
                    </div>
                  </div>
                  <div className="job-description">
                          {job.description}
                          {this.renderAccomplishments(job)}
                        </div>
                </div>
              );
            })}
          </section>
          <section className="publications pagebreak">
            <a id="publications" href="#publications"><h2>
              <ImBooks className="icon" />
              Publications
            </h2></a>
            <div className="standalone">
              {this.props.publicationsDescription}
            </div>
            {this.props.publications.map(entry => this.renderPublication(entry))}
          </section>
          <section className="teaching pagebreak">
            <a id="teaching" href="#teaching"><h2>
              <LiaChalkboardTeacherSolid className="icon" />
              Course and workshop development
            </h2></a>
            <div className="standalone">
              {this.props.teachingDescription}
            </div>
            {this.props.educationalProducts.map(entry => this.renderCourse(entry))}
          </section>
          <section>
          <a id="education" href="#education"><h2>
              <ImLibrary className="icon" />
              Education
            </h2></a>
            {this.props.education.map(entry => this.renderDegree(entry))}
          </section>
          <section>
          <a id="projects-and-software" href="#projects-and-software"><h2>
              <IoIosCreate className="icon" />
              Projects and Software
            </h2></a>
            {this.props.projects.map(entry => this.renderProject(entry))}
          </section>
          <section>
          <a id="grants" href="#grants"><h2>
              <FaTrophy className="icon" />
              Grants and Contracts
            </h2></a>
            {this.props.grants.map(entry => this.renderGrant(entry))}
          </section>
          <section>
          <a id="awards" href="#awards"><h2>
              <FaTrophy className="icon" />
              Awards
            </h2></a>
            {this.props.awards.map(entry => this.renderAward(entry))}
          </section>
          <section>
          <a id="skills" href="#skills"><h2>
              <GiFairyWand className="icon" />
              Skills
            </h2></a>
            <div id="skills-content">
              {this.props.skills.sort((a, b) => a.tag.localeCompare(b.tag)).map(skill => {
                return (
                  <div className="skillName" key={skill.tag}>
                    <span>{this.renderKeyword(skill)}</span>
                    { (typeof skill.level !== "undefined") ? this.skillLevel(skill.level) : null }
                  </div>
                );
              })}
            </div>
          </section>
          {/* {this.getMiscSection(this.props)} */}
        </div>
      </div>
    );
  }
}

export default Resume;